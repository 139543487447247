<template>
  <div class="scheduler-area">
    <div class="scheduler">
      <div class="appt-icon-back mobile" @click="back"></div>
      <div class="sch-title">
        <div class="lbl-appt-title">{{ title }}</div>
      </div>
      <div class="sch-control" :class="'schedule-' + type">
        <div class="widget-container">
          <div
            v-for="(step, index) in steps"
            class="appt-ctl-item"
            :class="{
              active: step.active || index == currentStage,
              complete: step.complete || index < currentStage,
            }"
            :key="index"
          >
            <div class="item-pointer"></div>
            <div class="item-label">{{ step.label }}</div>
            <div class="item-line before-after"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStage: Number,
    steps: Array,
    title: String,
    type: String,
  },
  methods: {
    back: function () {
      this.$emit("back");
    },
  },
};
</script>
