<template>
  <div class="confirm-stage" :class="{ active: formSectionComplete }">
    <div class="d-title">
      <span class="lbl-cap8 text-bold">3. Date & Time:</span>
      <div class="action-edit" @click="gotoEdit(2)" v-if="formSectionComplete">
        Edit
      </div>
    </div>
    <div class="d-detail" v-if="formSectionComplete && label">
      <div class="lbl-cap6">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    section() {
      return this.$store.state.components.appointment.steps[2];
    },
    formStore() {
      return this.$store.state.user.requested_appointment;
    },
    formSectionComplete() {
      return this.$store.getters.formAppointment;
    },
    label() {
      return this.$store.getters.appointmentLabel;
    },
  },
  methods: {
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },
  },
};
</script>
