<template>
  <div class="appt-confirm">
    <div class="appt-cap1 t-bold">{{ title }}</div>
    <div class="w-100 mt-2" v-if="criticalError">
      <p class="text-center text-red text-italic">
        <strong
          >Something went wrong. Please try to submit the form again.</strong
        >
      </p>
    </div>
    <div class="w-100 mt-2" v-if="!formComplete">
      <p class="text-center text-red text-italic">
        <strong
          >Form not completed. Please go back and enter in required
          fields.</strong
        >
      </p>
    </div>
    <div class="w-100 mt-2" v-if="validationErrors">
      <p class="w-100 text-center text-red text-italic">
        <strong
          >Form not completed. Please correct the following fields and try
          again.</strong
        >
      </p>
      <p class="w-100 text-center text-red">
        <span v-for="(error, index) in validationErrors" :key="index"
          >{{ index }}: {{ error[0] }}</span
        >
      </p>
    </div>
    <div class="appt-confirm-stages" v-if="formType == 'quote'">
      <FormStoreReview @goToEdit="gotoEdit(0)" :number="1"></FormStoreReview>
      <FormServiceReview
        @goToEdit="gotoEdit(1)"
        :number="2"
      ></FormServiceReview>
      <FormContactReview
        @goToEdit="gotoEdit(2)"
        :number="3"
      ></FormContactReview>
    </div>
    <div class="appt-confirm-stages" v-if="formType == 'checkout'">
      <FormStoreReview @goToEdit="gotoEdit(0)" :number="1"></FormStoreReview>
      <FormCartReview @goToEdit="gotoEdit(1)" :number="2"></FormCartReview>
      <FormContactReview
        @goToEdit="gotoEdit(2)"
        :number="3"
      ></FormContactReview>
    </div>
    <div class="appt-confirm-stages" v-if="formType == 'appointment'">
      <FormStoreReview @goToEdit="gotoEdit(0)" :number="1"></FormStoreReview>
      <FormServiceReview
        @goToEdit="gotoEdit(1)"
        :number="2"
      ></FormServiceReview>
      <FormDateTime @goToEdit="gotoEdit(2)" :number="3"></FormDateTime>
      <FormContactReview
        @goToEdit="gotoEdit(3)"
        :number="4"
      ></FormContactReview>
    </div>
    <div class="goto-next">
      <div class="btn-back c-pointer" @click="back">Back</div>
      <div
        class="btn-next btn-book c-pointer active"
        :class="{ disabled: !formComplete }"
        @click="process_form"
        v-if="!formProcessing"
        :disabled="!formComplete"
      >
        Book Now
      </div>
      <div class="btn-next btn-book c-pointer active" v-if="formProcessing">
        Processing
      </div>
    </div>
  </div>
</template>

<script>
import FormStoreReview from "./../partials/FormStoreReview";
import FormServiceReview from "./../partials/FormServiceReview";
import FormContactReview from "./../partials/FormContactReview";
import FormDateTime from "@/components/partials/FormDateTime";
import FormCartReview from "../checkout/FormCartReview.vue";
export default {
  props: {
    title: String,
    formType: String,
  },
  components: {
    FormStoreReview,
    FormServiceReview,
    FormContactReview,
    FormDateTime,

    FormCartReview,
  },
  created() {
    this.setUserPageURL();
  },
  mounted() {
    if (this.formType == "quote") {
      if (
        this.$store.getters.validQuoteState &&
        !this.formComplete &&
        !this.$store.getters.formCoupons
      ) {
        this.$emit("goToEdit", this.$store.getters.validQuoteState);
      }
    }
    if (this.formType == "appointment") {
      if (this.$store.getters.validAppointmentState && !this.formComplete) {
        this.$emit("goToEdit", this.$store.getters.validAppointmentState);
      }
    }
  },
  computed: {
    formComplete() {
      if (this.formType == "quote") {
        return this.$store.getters.quoteFormComplete;
      }
      if (this.formType == "appointment") {
        return this.$store.getters.appointmentFormComplete;
      }
      if (this.formType == "checkout") {
        return this.$store.getters.checkoutFormComplete;
      }
      if(this.formType == "affiliate"){
        return this.$store.getters.affiliateFormComplete;
      }
      return false;
    },
    formProcessing() {
      return this.$store.state.form_processing;
    },
    criticalError() {
      return this.$store.state.critical_form_error;
    },
    validationErrors() {
      if (this.formType == "quote" && this.$store.getters.quoteErrors) {
        //return this.$store.getters.quoteErrors;
        return this.$store.state.components.quote.errors;
      }
      if (
        this.formType == "appointment" &&
        this.$store.getters.appointmentErrors
      ) {
        //return this.$store.getters.appointmentErrors;
        return this.$store.state.components.appointment.errors;
      }
      return false;
    },
    contactErrors() {
      const vm = this;
      if (this.validationErrors.length > 0 && this.validationErrors) {
        vm.$store.getters.quoteErrors.forEach(function (item) {
          if (
            item == "first_name" ||
            item == "last_name" ||
            item == "email" ||
            item == "phone"
          ) {
            return true;
          }
        });
      }
      return false;
    },
    selectedUserStoreLocation() {
      if (
        typeof this.$store.state.user.selectedStoreForInquiry["location-id"] !==
        undefined
      ) {
        return this.$store.state.user.selectedStoreForInquiry["location-id"];
      }
      return null;
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    setUserPageURL() {
      this.$store.commit("setUserCurrentPage");
    },
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },

    process_form() {
      const vm = this;
      if (this.formComplete) {
        this.$store
          .dispatch("submitLead", this.formType)
          .then(function () {
            if (vm.$store.state.form_response.responseCode == 1) {
              vm.$gtm.trackEvent({
                event: "generate_lead",
                category: "Lead",
                action: vm.formType + " lead captured",
                label: vm.selectedUserStoreLocation,
                noninteraction: false,
              });
              //this.$store.commit("resetUserForm", { type: "quote" });
            }
          })
          .catch(function () {});
      }
    },
  },
};
</script>
<style>
.text-red {
  color: red;
}
</style>
