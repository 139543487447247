<template>
  <div class="appt-store">
    <div class="appt-cap1 t-bold mb-4">{{ title }}</div>
    <div class="cur-my-store" v-if="userHasStoreSelected">
      <SingleStore
        :active="true"
        :visible="false"
        :selected="true"
        :address="userHasStoreSelected.address"
        :distance="userHasStoreSelected.distance"
        :locationId="userHasStoreSelected['location-id']"
      ></SingleStore>
      <div class="split-row appt-cap3">Or</div>
    </div>
    <div class="geolocate-area md-b15">
      <div class="search">
        <gmap-autocomplete
          class="input-auto-complete"
          @place_changed="updatePlace"
          placeholder="Search for a store by City, State, or Zip"
        ></gmap-autocomplete>
        <i aria-hidden="true" class="fa_search fa fa-search"></i>
      </div>
      <div class="btn-location">
        <a class="getLocation c-pointer" @click.stop="getCurrentLocation()">
          <div class="b-loc">
            <img src="./../../assets/geolocation.png" alt="geolocation-icon" />
            <span>Current Location</span>
          </div>
        </a>
      </div>
    </div>
    <div class="radio-area mobile">
      <b-form-group label="">
        <b-form-radio-group
          v-model="view_selector"
          :options="options"
          class="lbl-radio"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div class="store-map-area" v-if="view_selector === 'map'">
      <gmap-map
        :center="center"
        :zoom="map_zoom"
        style="width: 100%; height: 300px"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers.slice(0, 5)"
          :position="m.position"
          @click="center = m.position"
        ></gmap-marker>
      </gmap-map>
    </div>
    <div class="store-result-area" v-if="storeResults.length > 0">
      <SingleStore
        v-for="(place, i) in storeResults.slice(0, 5)"
        :key="i"
        @click.native="selLocation(place)"
        :address="place.address"
        :selected="false"
        :active="
          userHasStoreSelected &&
          userHasStoreSelected['location-id'] == place['location-id']
        "
        :visible="true"
        :distance="place.distance"
        :locationId="place['location-id']"
      ></SingleStore>
    </div>
    <div class="store-no-result text-italic text-center" v-else>
      {{ no_store_selected() }}
    </div>

    <div class="goto-next">
      <div class="btn-cancel c-pointer" @click="hideModal">Cancel</div>
      <div
        class="btn-next add-next"
        :class="{ 'active c-pointer': complete }"
        @click="next"
      >
        Continue to Services
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { gmapApi } from "vue2-google-maps";
import { BASE_URL } from "../../utils/constants";
import SingleStore from "./../partials/SingleStore";

export default {
  components: {
    SingleStore,
  },
  props: {
    title: String,
  },
  data: () => {
    return {
      store_selector: "",
      center: { lat: 20.4, lng: -80.08 },
      map_zoom: 4,
      markers: [],
      places: [],
      view_selector: "map",
      options: [
        { item: "map", name: "Map View" },
        { item: "list", name: "List View" },
      ],
    };
  },
  created() {
    if (this.userHasStoreSelected && this.userStore == null) {
      this.selLocation(this.userMyStore);
    }
  },
  mounted() {
    this.mapResults();
  },
  computed: {
    google: gmapApi,
    complete() {
      return this.$store.getters.formStore;
    },
    appointment() {
      return this.$store.state.appointment;
    },
    userStore() {
      return this.$store.state.user.selectedStoreForInquiry;
    },
    userMyStore() {
      return this.$store.state.myStore;
    },
    userHasStoreSelected() {
      if (this.userStore !== null) {
        return this.userStore;
      }
      if (this.userMyStore !== null) {
        return this.userMyStore;
      }
      return false;
    },
    storeResults() {
      return this.$store.state.user.search_results;
    },
  },
  methods: {
    no_store_selected() {
      let lbl = "You currently have no store selected.";
      if (this.userHasStoreSelected) {
        lbl = "Search for a different store above.";
      } else {
        lbl = "Search for a different store above.";
      }
      return lbl;
    },
    updatePlace(place) {
      var sel_city = "";
      var sel_location = "";
      var userLocation = {};
      if (place) {
        userLocation.lat = place.geometry.location.lat();
        userLocation.lon = place.geometry.location.lng();
        for (var i = 0; i < place.address_components.length; i++) {
          for (var b = 0; b < place.address_components[i].types.length; b++) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (place.address_components[i].types[b] == "locality") {
              //this is the object you are looking for
              sel_city = place.address_components[i].long_name;
            }
          }
          for (var c = 0; c < place.address_components[i].types.length; c++) {
            if (
              place.address_components[i].types[c] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for
              sel_location = place.address_components[i].long_name;
            }
          }
        }
      }
      self.center = {
        lat: parseFloat(userLocation.lat),
        lng: parseFloat(userLocation.lon),
      };

      this.selCity({ city: sel_city, region: sel_location });
    },
    retrieveFCID(storeNumber) {
      // Deprecated
      axios
        .get(
          `${BASE_URL}/wp-json/tint-world-api/v1/get-store-by-id/` + storeNumber
        )
        .then(function (response) {
          return response.data.meta;
        });
    },
    getCurrentLocation() {
      // Refactor into Action and Deprecate
      var self = this;
      axios
        .get("https://ipinfo.io/?token=762408ef747fbe")
        .then(function (response) {
          var geo = response.data.loc.split(",");
          var lat = geo[0];
          var long = geo[1];
          var userLocation = response.data;
          var sel_city = userLocation.city;
          var sel_location = userLocation.region;
          self.center = { lat: parseFloat(lat), lng: parseFloat(long) };
          self.selCity({ city: sel_city, region: sel_location });
        });
    },
    selCity(city) {
      const vm = this;
      this.$store
        .dispatch("getGeoResults", {
          search: { ["location-search"]: city.city + ", " + city.region },
        })
        .then(function () {
          vm.mapResults();
        });
    },
    mapResults() {
      const vm = this;
      if (vm.storeResults.length == 0) {
        vm.center = { lat: 20.4, lng: -80.08 };
        vm.markers = [];
        vm.places = [];
        vm.map_zoom = 4;
        return;
      }
      let new_makers = [];
      for (let k of vm.storeResults) {
        new_makers.push({
          position: {
            lat: parseFloat(k.address.coordinates.latitude),
            lng: parseFloat(k.address.coordinates.longitude),
          },
        });
      }
      vm.center = new_makers[0].position;
      vm.markers = new_makers;
      vm.map_zoom = 7;
    },
    selLocation(place) {
      var new_makers = [];
      new_makers.push({
        position: {
          lat: parseFloat(place.address.coordinates.latitude),
          lng: parseFloat(place.address.coordinates.longitude),
        },
      });
      this.store_selector = place["location-id"];
      this.center = new_makers[0].position;
      this.map_zoom = 7;
      this.markers = new_makers;
      this.$store.commit("setFormLocation", place);
      if (this.$store.state.components.appointment.active) {
        this.$store.commit("resetAppointment");
      }
    },
    next() {
      if (this.complete) {
        this.$emit("next");
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
  },
};
</script>
