<template>
  <div class="appt-contact">
    <div class="appt-cap1 t-bold">{{ title }}</div>
    <div class="vehicle-info-area">
      <div
        class="lbl-information w-100 mt-2"
        v-if="hasAutomotive || showAutomotive"
      >
        Vehicle Type:
      </div>
      <div class="vehicle-sel-area" v-if="hasAutomotive || showAutomotive">
        <div class="d-flex flex-1 flex-row">
          <div class="sel-area sel-year">
            <b-form-select
              class="custom-select-vehicle"
              @change="selYear"
              v-model="year"
              :options="options_year"
            ></b-form-select>
          </div>
          <div class="sel-area sel-make">
            <b-form-select
              class="custom-select-vehicle"
              @change="selMake"
              v-model="make"
              :options="options_make"
            ></b-form-select>
          </div>
        </div>
        <div class="d-flex flex-1 flex-row">
          <div class="sel-area sel-model">
            <b-form-select
              class="custom-select-vehicle"
              v-model="model"
              :options="options_model"
            ></b-form-select>
          </div>
          <div class="sel-area sel-door">
            <b-form-select
              class="custom-select-vehicle"
              v-model="doors"
              :options="options_door"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="lbl-information w-100 mt-2" v-if="hasResidential">
        Residential Type:
      </div>
      <div class="vehicle-sel-area" v-if="hasResidential">
        <div
          class="sel-area sel-residential"
          :class="{ activated: active_residential }"
        >
          <b-form-select
            class="custom-select-vehicle"
            v-model="residential_type"
            :options="options_residential"
            required
          ></b-form-select>
        </div>
      </div>
      <div class="lbl-information w-100 mt-2" v-if="hasCommercial">
        Commercial Type:
      </div>
      <div class="vehicle-sel-area" v-if="hasCommercial">
        <div
          class="sel-area sel-building"
          :class="{ activated: active_building }"
        >
          <b-form-select
            class="custom-select-vehicle"
            v-model="commercial_type"
            :options="options_building"
          ></b-form-select>
        </div>
      </div>
      <div class="lbl-information w-100 mt-2" v-if="hasMarine">
        Marine Type:
      </div>
      <div class="vehicle-sel-area" v-if="hasMarine">
        <div class="sel-area sel-vessel" :class="{ activated: active_vessel }">
          <b-form-select
            class="custom-select-vehicle"
            v-model="marine_type"
            :options="options_vessel"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="person-info-area">
      <div class="lbl-information">Your Information:</div>
      <b-form>
        <div class="person-info">
          <div class="flex-1 form-ctrl" :class="{ activated: firstNameError }">
            <b-input
              class="custom-input"
              placeholder="First Name*"
              v-model="first_name"
              type="text"
              :state="validateField('first_name')"
              required
            ></b-input>
          </div>
          <div class="flex-1 form-ctrl" :class="{ activated: lastNameError }">
            <b-input
              class="custom-input"
              placeholder="Last Name*"
              v-model="last_name"
              type="text"
              :state="validateField('last_name')"
              required
            ></b-input>
          </div>
        </div>
        <div class="person-info">
          <div class="flex-1 form-ctrl">
            <b-input
              class="custom-input"
              placeholder="Phone*"
              v-model="phone"
              type="tel"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              :state="validateField('phone')"
              required
            ></b-input>
          </div>
          <div class="flex-1 form-ctrl">
            <b-input
              class="custom-input"
              placeholder="Email*"
              v-model="email"
              type="email"
              :state="validateEmail()"
              required
            ></b-input>
          </div>
        </div>
        <div class="person-info">
          <div class="flex-1 form-ctrl">
            <b-form-textarea
              id="textarea"
              v-model="notes"
              class="custom-textarea"
              placeholder="Tell us about your project*"
              :state="validateField('notes')"
            ></b-form-textarea>
          </div>
        </div>
        <div class="person-info mt-2">
          <div class="flex-1 form-ctrl">
            <b-form-checkbox name="sms-opt-in" v-model="sms_opt_in">
              <span style="font-size: 14px"
                >By submitting this form and signing up for texts, you consent
                to receive informational and marketing SMS messages from Tint
                World at the number provided, including messages sent by
                autodialer. Consent is not a condition of purchase. Msg & data
                rates may apply. Msg frequency varies. Unsubscribe at any time
                by replying STOP or clicking the unsubscribe link (where
                available).</span
              >
            </b-form-checkbox>
          </div>
        </div>
        <div class="address-form" v-if="off_site">
          <div class="lbl-information">
            Off-Site Visit Location:
            <i class="fa fa-exclamation-circle" id="tooltip123"></i>
          </div>
          <b-tooltip ref="tooltip" target="tooltip123">
            We can come to your location to perform work and provide estimates.
            <br />
            Select this option if you require an off-site visit.
          </b-tooltip>
          <div class="person-info">
            <div class="flex-1 form-ctrl">
              <b-input
                class="custom-input"
                placeholder="Address Line 1*"
                v-model="street"
                type="text"
                required
              ></b-input>
            </div>
            <div class="flex-1 form-ctrl">
              <b-input
                class="custom-input"
                placeholder="Apt, Suite, Building (optional)"
                v-model="street_2"
                type="text"
              ></b-input>
            </div>
          </div>
          <div class="person-info">
            <div class="flex-1 form-ctrl">
              <b-input
                class="custom-input"
                placeholder="City*"
                v-model="city"
                type="text"
                required
              ></b-input>
            </div>
            <div class="flex-1 form-ctrl">
              <div class="person-info">
                <div class="flex-1 pd-lr5">
                  <b-form-select
                    class="custom-select-vehicle"
                    v-model="region"
                    :options="options_state"
                  ></b-form-select>
                </div>
                <div class="flex-1 pd-lr5">
                  <b-input
                    class="custom-input"
                    placeholder="Zip*"
                    v-model="postal_code"
                    type="text"
                    required
                  ></b-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
    <div class="goto-next">
      <div class="btn-back c-pointer" @click="back">Back</div>
      <div
        class="btn-next add-next"
        :class="{ 'active c-pointer': complete }"
        @click="next"
      >
        Next
      </div>
    </div>
  </div>
</template>
<script>
const default_opt_year = { value: null, text: "Select Year*" };
const default_opt_make = { value: null, text: "Select Make*" };
const default_opt_model = { value: null, text: "Select Model*" };
const default_opt_residential = {
  value: null,
  text: "Select Residential Type*",
};
const default_opt_building = { value: null, text: "Select Building Type*" };
const default_opt_vessel = { value: null, text: "Select Vessel Type*" };
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  props: {
    title: String,
    showAutomotive: Boolean,
  },
  data: () => {
    return {
      activeErrorDoor: false,
      activeErrorYear: false,
      activeErrorMake: false,
      activeErrorModel: false,
      firstNameError: false,
      lastNameError: false,
      active: true,
      active_residential: false,
      active_building: false,
      active_vessel: false,
      showModal: false,
      sel_year: null,
      sel_make: null,
      sel_model: null,
      sel_door: null,
      options_door: [
        { value: null, text: "# of Doors", disabled: true },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
      ],
      sel_residential: null,
      sel_building: null,
      sel_vessel: null,
      form: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        description: "",
        photos: [],
      },
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file",
      address: {
        street: "",
        apt: "",
        city: "",
        state: null,
        zip: "",
      },
      sms_opt_in: true,
    };
  },
  mounted() {
    // Function below will check whether off_site is active and allowed to be active. If a user selects off-site without a residential/commercial/marine, we'll force remove it
    if (this.$store.state.user.off_site) {
      if (!this.hasCommercial && !this.hasResidential && !this.hasMarine) {
        this.$store.commit("cancelOffsite");
      }
    }
  },
  computed: {
    first_name: {
      get() {
        return this.$store.state.user.first_name;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "first_name");
        } else {
          this.$store.commit("setUser", { first_name: value });
        }
      },
    },
    last_name: {
      get() {
        return this.$store.state.user.last_name;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "last_name");
        } else {
          this.$store.commit("setUser", { last_name: value });
        }
      },
    },
    phone: {
      get() {
        return this.$store.state.user.phone;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "phone");
        } else {
          this.$store.commit("setUser", { phone: value });
        }
      },
    },
    email: {
      get() {
        return this.$store.state.user.email;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "email");
        } else {
          this.$store.commit("setUser", { email: value });
        }
      },
    },
    notes: {
      get() {
        return this.$store.state.user.notes;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "notes");
        } else {
          this.$store.commit("setUser", { notes: value });
        }
      },
    },
    off_site: {
      get() {
        return this.$store.state.user.off_site;
      },
    },
    street: {
      get() {
        return this.$store.state.user.address.street;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "street");
        } else {
          this.$store.commit("setUser", { street: value });
        }
      },
    },
    street_2: {
      get() {
        return this.$store.state.user.address.street_2;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "street_2");
        } else {
          this.$store.commit("setUser", { street_2: value });
        }
      },
    },
    city: {
      get() {
        return this.$store.state.user.address.city;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "city");
        } else {
          this.$store.commit("setUser", { city: value });
        }
      },
    },
    region: {
      get() {
        return this.$store.state.user.address.region;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "region");
        } else {
          this.$store.commit("setUser", { region: value });
        }
      },
    },
    postal_code: {
      get() {
        return this.$store.state.user.address.postal_code;
      },
      set(value) {
        if (value == "") {
          this.$store.commit("setUserFieldNull", "postal_code");
        } else {
          this.$store.commit("setUser", { postal_code: value });
        }
      },
    },
    service_object_type: {
      get() {
        return this.$store.state.user.service_object_type;
      },
      set(value) {
        this.$store.commit("setUser", { service_object_type: value });
      },
    },
    year: {
      get() {
        return this.$store.state.user.vehicle.year;
      },
      set(value) {
        this.$store.commit("setUser", { year: value });
      },
    },
    make: {
      get() {
        return this.$store.state.user.vehicle.make;
      },
      set(value) {
        this.$store.commit("setUser", { make: value });
      },
    },
    model: {
      get() {
        return this.$store.state.user.vehicle.model;
      },
      set(value) {
        this.$store.commit("setUser", { model: value });
      },
    },
    doors: {
      get() {
        return this.$store.state.user.vehicle.doors;
      },
      set(value) {
        this.$store.commit("setUser", { doors: value });
      },
    },
    marine_type: {
      get() {
        return this.$store.state.user.marine_type;
      },
      set(value) {
        this.$store.commit("setUser", { marine_type: value });
      },
    },
    residential_type: {
      get() {
        return this.$store.state.user.residential_type;
      },
      set(value) {
        this.$store.commit("setUser", { residential_type: value });
      },
    },
    commercial_type: {
      get() {
        return this.$store.state.user.commercial_type;
      },
      set(value) {
        this.$store.commit("setUser", { commercial_type: value });
      },
    },
    validEmail() {
      return this.$store.getters.formEmail;
    },
    validationErrors() {
      return this.$store.getters.quoteErrors;
    },
    userServices() {
      return this.$store.getters.userServices;
    },
    hasAutomotive() {
      return this.$store.getters.hasAutomotive;
    },
    hasResidential() {
      return this.$store.getters.hasResidential;
    },
    hasCommercial() {
      return this.$store.getters.hasCommercial;
    },
    hasMarine() {
      return this.$store.getters.hasMarine;
    },
    complete() {
      return this.$store.getters.formContact;
    },
    user() {
      return this.$store.state.user;
    },
    options_year() {
      var years = [];
      years.push(default_opt_year);
      if (this.$store.state.carYears && this.$store.state.carYears.length > 0) {
        for (var k of this.$store.state.carYears) {
          years.push({ value: k, text: k });
        }
      }
      return years;
    },
    options_make() {
      var makes = [];
      makes.push(default_opt_make);
      if (this.$store.state.carMakes && this.$store.state.carMakes.length > 0) {
        for (var k of this.$store.state.carMakes) {
          makes.push({ value: k.make, text: k.make });
        }
      }
      return makes;
    },
    options_model() {
      var models = [];
      models.push(default_opt_model);
      if (!this.make || this.make == null) {
        return models;
      }
      if (this.$store.state.carMakes && this.$store.state.carMakes.length > 0) {
        var findModels = this.$store.state.carMakes.find(
          (m) => m.make === this.make
        ).models;
        for (var k of findModels) {
          models.push({ value: k, text: k });
        }
      }
      return models;
    },
    options_residential() {
      var data = [];
      data.push(default_opt_residential);
      if (
        this.$store.state.appt_options_residential &&
        this.$store.state.appt_options_residential.length > 0
      ) {
        for (var k of this.$store.state.appt_options_residential) {
          data.push({ value: k, text: k });
        }
      }
      return data;
    },
    options_building() {
      var data = [];
      data.push(default_opt_building);
      if (
        this.$store.state.appt_options_commercial &&
        this.$store.state.appt_options_commercial.length > 0
      ) {
        for (var k of this.$store.state.appt_options_commercial) {
          data.push({ value: k, text: k });
        }
      }
      return data;
    },
    options_vessel() {
      var data = [];
      data.push(default_opt_vessel);
      if (
        this.$store.state.appt_options_marine &&
        this.$store.state.appt_options_marine.length > 0
      ) {
        for (var k of this.$store.state.appt_options_marine) {
          data.push({ value: k, text: k });
        }
      }
      return data;
    },
    options_state() {
      var data = [];
      data.push({ value: null, text: "State*" });
      if (this.$store.state.regions && this.$store.state.regions.length > 0) {
        for (var k of this.$store.state.regions) {
          data.push({ value: k.region_code, text: k.region_code });
        }
      }
      return data;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    userVehicles() {
      return this.$store.getters.userVehicles;
    },
  },
  methods: {
    validateField(name) {
      const vm = this;
      if (vm.validationErrors) {
        if (vm.validationErrors.indexOf(name) !== -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return null;
      }
    },
    validateEmail() {
      const vm = this;
      if (vm.validationErrors) {
        if (vm.validationErrors.indexOf("email") !== -1) {
          return false;
        } else {
          return true;
        }
      }
      if (this.$store.state.user.email == null || this.validEmail) {
        return null;
      } else {
        return this.validEmail;
      }
    },
    next() {
      if (this.complete) {
        this.$emit("next");
      }
    },
    back() {
      this.$emit("back");
    },
    changeSelect() {
      this.validation();
    },
    selYear(value) {
      this.$store.dispatch("loadCarMakes", value);
    },
    selMake() {
      this.$store.commit("resetUserVehicleModel");
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    updateName(e) {
      this.$store.commit("setUser", { first_name: e.target.value });
    },
  },
};
</script>
<style>
.vehicle-sel-area {
  margin: 10px -5px;
}
</style>
