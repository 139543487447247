<template>
  <div class="confirm-stage" :class="{ active: formSectionComplete }">
    <div class="d-title">
      <span class="lbl-cap8 text-bold">{{ number }}. My Cart</span>
      <div class="action-edit" @click="gotoEdit(1)" v-if="formSectionComplete">
        Edit
      </div>
    </div>
    <div class="d-detail" v-if="formSectionComplete">
      <div class="lbl-cap6 text-cap">
        <span v-for="item in formStore()" :key="item.id">{{ item.name }}</span>
        <span v-for="service in formStore" :key="service.id"
          >{{ service.category }} - {{ service.name }},
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
  },
  computed: {
    section() {
      return this.$store.state.components.checkout.steps[1];
    },
    user() {
      return this.$store.state.user;
    },

    formSectionComplete() {
      return this.$store.getters.userHasCart;
    },
  },
  methods: {
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },
    formStore() {
      return this.$store.state.cart.items;
    },
  },
};
</script>
