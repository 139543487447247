<template>
  <div
    class="store-row c-pointer"
    :class="{ active: active, selected: selected }"
  >
    <div class="radio-area chk-radio" v-if="!selected">
      <b-form-group label="">
        <b-form-radio
          name="place_radio"
          :value="locationId"
          v-if="visible"
          v-model="selected_location"
        ></b-form-radio>
      </b-form-group>
    </div>
    <div class="store-location1 appt-cap3 desktop">
      Tint World&reg;<br />
      {{ address.city }}, {{ address.region }}
    </div>
    <div class="store-location2 appt-cap4">
      <div class="mobile">
        <span class="appt-cap3"
          >Tint World&reg; {{ address.city }}, {{ address.region }} </span
        ><span class="appt-cap4"
          >&nbsp;({{ distance.distanceMiles.toFixed(1) }} mi)</span
        >
      </div>
      {{ address.street }}<br />
      {{ address.city }}, {{ address.region }} {{ address.postalCode }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    visible: Boolean,
    locationId: String,
    address: Object,
    distance: Object,
    selected: Boolean,
  },
  computed: {
    selectedLocation() {
      if (this.$store.getters.formStore) {
        return this.$store.getters.formStore["location-id"];
      }
      return false;
    },
    selected_location: {
      get() {
        if (this.$store.getters.formStore) {
          return this.$store.getters.formStore["location-id"];
        }
        return false;
      },
      set() {
        this.$emit("selectLocation");
      },
    },
  },
  created() {},
  methods: {
    selLocation: function (key) {
      this.$emit("selectLocation", key);
      //this.$store.commit('setFormLocation', {type: 'store',data: place});
    },
  },
};
</script>
<style>
.appt-store .store-row.selected {
  padding: 15px;
}
</style>
