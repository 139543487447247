<template>
  <div class="confirm-stage" :class="{ active: formSectionComplete }">
    <div class="d-title">
      <span class="lbl-cap8 text-bold">{{ number }}. Store Selected</span>
      <div class="action-edit" @click="gotoEdit(0)" v-if="formSectionComplete">
        Edit
      </div>
    </div>
    <div class="d-detail" v-if="formSectionComplete">
      <div class="lbl-cap6 text-bold">
        Tint World<sup>&reg;</sup> {{ formStore.address.city }}
      </div>
      <div class="lbl-cap6">{{ formStore.address.street }}</div>
      <div class="lbl-cap6">
        {{ formStore.address.city }}, {{ formStore.address.region }}
        {{ formStore.address.postalCode }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
  },
  computed: {
    section() {
      return this.$store.components.appointment.steps[0];
    },
    formStore() {
      return this.$store.state.user.selectedStoreForInquiry;
    },
    formSectionComplete() {
      return this.$store.getters.formStore;
    },
  },
  methods: {
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },
  },
};
</script>
