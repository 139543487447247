<template>
  <div class="confirm-stage" :class="{ active: formSectionComplete }">
    <div class="d-title">
      <span class="lbl-cap8 text-bold">{{ number }}. Contact Info:</span>
      <div class="action-edit" @click="gotoEdit(2)" v-if="formSectionComplete">
        Edit
      </div>
    </div>
    <div class="d-detail" v-if="formSectionComplete">
      <div class="flex-1 d-flex flex-column">
        <div class="flex-1 md-b15 vehicle-info" v-if="hasVehicle">
          <div class="lbl-cap7 text-bold text-cap">Vehicle Info</div>
          <div class="lbl-cap6">
            {{ formStore.vehicle.year }} {{ formStore.vehicle.make }}
            {{ formStore.vehicle.model }} ({{ formStore.vehicle.doors }} Doors)
          </div>
        </div>
        <div
          class="flex-1 md-b15 vehicle-info"
          v-if="
            formStore.residential_type !== null && $store.getters.hasResidential
          "
        >
          <div class="lbl-cap7 text-bold text-cap">Home Type</div>
          <div class="lbl-cap6">{{ formStore.residential_type }}</div>
        </div>
        <div
          class="flex-1 md-b15 vehicle-info"
          v-if="
            formStore.commercial_type !== null && $store.getters.hasCommercial
          "
        >
          <div class="lbl-cap7 text-bold text-cap">Building Type</div>
          <div class="lbl-cap6">{{ formStore.commercial_type }}</div>
        </div>
        <div
          class="flex-1 md-b15 vehicle-info"
          v-if="formStore.marine_type !== null && $store.getters.hasMarine"
        >
          <div class="lbl-cap7 text-bold text-cap">Marine Type</div>
          <div class="lbl-cap6">{{ formStore.marine_type }}</div>
        </div>
        <div class="flex-1 md-b15">
          <div class="lbl-cap7 text-bold">Your Info</div>
          <div class="lbl-cap6">
            {{ formStore.first_name }} {{ formStore.last_name }}
          </div>
          <div class="lbl-cap6">{{ formStore.phone }}</div>
          <div class="lbl-cap6">{{ formStore.email }}</div>
        </div>
        <div class="flex-1 md-b15">
          <div class="lbl-cap7 text-bold">About My Project</div>
          <div class="lbl-cap6">{{ formStore.notes }}</div>
        </div>
        <div
          class="flex-1 md-b15"
          v-if="hasOffsite"
          v-html="showOffSiteLocation()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
  },
  computed: {
    formStore() {
      return this.$store.state.user;
    },
    formSectionComplete() {
      return this.$store.getters.formContact;
    },
    hasOffsite() {
      return this.$store.state.user.off_site;
    },
    hasVehicle() {
      return this.$store.getters.userHasVehicle;
    },
  },
  methods: {
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },
    showOffSiteLocation() {
      let html =
        '<div class="lbl-cap7 text-bold">Off-Site Visit Location:</div>';
      html += '<div class="lbl-cap6">' + this.$store.state.user.address.street;
      if (this.$store.state.user.address.street_2 !== null) {
        html += ", " + this.$store.state.user.address.street_2;
      }
      html += "</div>";
      html +=
        '<div class="lbl-cap6">' +
        this.$store.state.user.address.city +
        ", " +
        this.$store.state.user.address.region +
        " " +
        this.$store.state.user.address.postal_code +
        "</div>";
      return html;
    },
  },
};
</script>
